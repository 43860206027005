export const handeleSuccess = (message, toast) => {
  return toast({
    title: message,
    status: "success",
    duration: 7000,
    isClosable: true,
    position: "top-right",
  });
};

export const handeleError = (error, toast, message) => {
  if (message) {
    return toast({
      title: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }
  if (error && error.response && error.response.data.message) {
    return toast({
      title: error.response.data.message || "something went wrong",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }

  if (error && error.response && error.response.data.errors) {
    let errors = error.response.data.errors;
    Object.entries(errors).map(([key, value]) => {
      return toast({
        title: value,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    });
  } else {
    return toast({
      title: "Something Wrong please tray again",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  }
};
