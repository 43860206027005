import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
const PasswordField = ({ name, label, placeholder }) => {
  const [seePasssword, setSeePasssword] = useState(false);
  return (
    <>
      <label className="w-100 chakra-form__label" htmlFor="password">
        {label}
      </label>
      <InputGroup size="lg" className="my-2">
        <Field
          as={Input}
          pr="4rem"
          type={seePasssword ? "text" : "password"}
          placeholder={placeholder ? placeholder : "Password"}
          name={name ? name : "password"}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={() => {
              return setSeePasssword(!seePasssword);
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        </InputRightElement>
      </InputGroup>
      <ErrorMessage
        name={name ? name : "password"}
        component="div"
        className="text-danger errorMsg"
      />
    </>
  );
};

export default PasswordField;
