import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneEcg } from "../../../../../../../../store/actions";
import { useParams, useHistory } from "react-router-dom";
import View from "../../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";

const ViewOneEcg = ({ getOneEcg, OneEcg, patient }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Ecg, setEcg] = useState(null);
  const { medicalId } = useParams();
  const toast = useToast();
  let history = useHistory();

  useEffect(() => {
    if (medicalId && patient) {
      getOneEcg(medicalId, toast, history, patient.id);
    }
  }, []);

  useEffect(() => {
    const { loading, error, data } = OneEcg;
    setLoading(loading);
    setError(error);
    if (data) {
      setEcg(data);
    }
  }, [OneEcg]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View ECG"
        one={Ecg}
        history={history}
      />
    );
  };
  return renderView();
};

const mapStateToProps = (state) => {
  return {
    OneEcg: state.OneEcg,
  };
};
export default connect(mapStateToProps, { getOneEcg })(ViewOneEcg);
