import { types } from "../types/types";
const medicalRecordTypes = types.medicalRecord;

const INIT_MEDICAL_RECORD_STATE = {
  loading: false,
  error: null,
  data: null,
};
// Condition
export const getConditionListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_CONDITIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_CONDITIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_CONDITIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneConditionReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

// Allergy
export const getAllergyListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_ALLERGY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_ALLERGY_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_ALLERGY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneAllergyReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_ALLERGY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_ALLERGY_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_ALLERGY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
//Ecg
export const getEcgListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_ECGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_ECGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_ECGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneEcgReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_ECG_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_ECG_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_ECG_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
//Lab Result
export const getLabResultListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_LAB_RESULTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_LAB_RESULTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_LAB_RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneLabResultReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_LABRESULT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_LABRESULT_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_LABRESULT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
// Medical Report
export const getMedicalReportListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_MEDICAL_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_MEDICAL_REPORTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_MEDICAL_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneMedicalReportReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_MEDICALREPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_MEDICALREPORT_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_MEDICALREPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
// X ray
export const getXrayListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_XRAYS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_XRAYS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_XRAYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneXrayReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_XRAY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_XRAY_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_XRAY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
// Radiology
export const getRadiologyListReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_RADIOLOGY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_RADIOLOGY_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_RADIOLOGY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getOneRadiologyReducer = (
  state = INIT_MEDICAL_RECORD_STATE,
  { type, payload }
) => {
  switch (type) {
    case medicalRecordTypes.GET_SINGLE_RADIOLOGY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case medicalRecordTypes.GET_SINGLE_RADIOLOGY_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case medicalRecordTypes.GET_SINGLE_RADIOLOGY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
//////////////
